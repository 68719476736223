import "./kibitzer.scss";

import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PendingIcon from "@mui/icons-material/Pending";
import {Alert, Button, CircularProgress, Tab, Tabs, Tooltip} from "@mui/material";
import classNames from "classnames";
import React from "react";
import {PlaygroundInfo} from "./playground";
import {
    Data,
    getLogLevelName,
    Outcome,
    PlaygroundDispatchApi,
    RunCondition,
    RunLog,
    RuntimeEvent,
    RuntimeSeries
} from "../../../api/playground/dispatch";
import {UpwireModalError, UpwireModalWarning} from "../../common/modal/modal";
import {fromNow} from "../../../../util/datetime";
import {Chit} from "../chip/chit";
import TabsPanel from "../../../../components/common/TabsPanel/TabsPanel";
import {useQuery} from "@tanstack/react-query";
import {useToken} from "../../../../state/UserContextProvider";

type JobKibitzerLogLineViewProps = {
    playground: PlaygroundInfo,
    log: RunLog,
}

function JobKibitzerLogLineView(props: JobKibitzerLogLineViewProps) {
    const {log} = props;
    const level = getLogLevelName(log.level);
    return <div className={classNames("log-line", {"level": level})}>
        <div className="log-line-time">{log.created_on}</div>
        <div className={classNames("log-line-text", level)}>{log.log}</div>
    </div>;
}


type JobKibitzerLogsViewProps = {
    playground: PlaygroundInfo,
    runId: string,
    stepId: string,
}

function JobKibitzerLogsView(props: JobKibitzerLogsViewProps) {
    const {playground} = props;
    const [expanded, setExpanded] = React.useState(false);

    const token = useToken();

    const {
        isFetching,
        data: allLogs,
        error
    } = useQuery(["run-id", props.runId, props.stepId], () => {
        const api = new PlaygroundDispatchApi(token);
        return api.getRunLogs(props.runId);
    });

    if (isFetching || !allLogs) {
        return <CircularProgress/>;
    }

    if (error) {
        console.error(error);
        return <Alert severity="error">Unable to fetch data...</Alert>;
    }

    const logs = allLogs.filter(log => log.step_id == props.stepId);
    logs.reverse();


    function toggleExpanded() {
        setExpanded(ex => !ex);
    }

    return <div className={classNames("logs", {"expanded": expanded})}>
        <div className="actions">
            <Button size="small" color="info" variant="outlined"
                    endIcon={expanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    onClick={toggleExpanded}>{expanded ? "Collapse" : "Expand"}</Button>
        </div>
        <div className="log-lines">
            {logs.length === 0 && <div className={"log-lines-no-content"}>No logs available for this step</div>}
            {logs.map((log, i) => {
                return <JobKibitzerLogLineView key={i} playground={playground} log={log}/>;
            })}
        </div>

    </div>;
}

function timeDifference(a: string, b: string): string | null {

    const diff = new Date(b).getTime() - new Date(a).getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor(diff / (1000 * 60));
    const seconds = Math.floor(diff / 1000);
    const milliseconds = diff;

    if (days > 0) {
        return `+${days} day${days > 1 ? "s" : ""}`;
    }

    if (hours > 0) {
        return `+${hours} hour${hours > 1 ? "s" : ""}`;
    }

    if (minutes > 0) {
        return `+${minutes} minute${minutes > 1 ? "s" : ""}`;
    }

    if (seconds > 0) {
        return `+${seconds} second${seconds > 1 ? "s" : ""}`;
    }

    if (milliseconds > 0) {
        return `+${milliseconds} millisecond${milliseconds > 1 ? "s" : ""}`;
    }

    return null;
}

function isData(data: any): data is Data {

    if (!data)
        return false;

    // check if data is empty
    if (Object.keys(data).length === 0) {
        return false;
    }

    return typeof data === "object";
}

function NodeOutcomeView(props: { outcome: Outcome }) {
    const {outcome} = props;
    if (outcome === "success") {
        return <div className="outcome completed"><DoneIcon color="success"/></div>;
    }

    if (outcome === "failure") {
        return <div className="outcome failed"><CloseIcon color="error"/></div>;
    }

    return <div className="outcome running"><PendingIcon color="warning"/></div>;
}

function EventTimeDifferenceView(props: { diff: string | null, time: string }) {

    const {diff, time} = props;
    return <>
        <Tooltip title={time}>
            <div className="event-time-node timeline-node">{diff}</div>
        </Tooltip>
    </>;
}

function DataNodeView(props: { data: Data, title: string }) {

    const [expanded, setExpanded] = React.useState(false);

    const {data, title} = props;

    function dataString() {
        return JSON.stringify(data, null, 4);
    }

    const dataView = expanded ? <div className="data">{dataString()}</div> : <></>;

    function toggleExpanded() {
        setExpanded(ex => !ex);
    }

    return <div className="data-node timeline-node" onClick={toggleExpanded}>
        <div className="data-node-title">
            {title}
            {expanded ?
                <ExpandLessIcon className="icon" color="disabled" fontSize="small"/> :
                <ExpandMoreIcon className="icon" color="disabled" fontSize="small"/>}

        </div>
        {dataView}
    </div>;
}


type NodeViewProps = {
    nodeId: string,
    playground: PlaygroundInfo,
    linkStepId?: string
}

function NodeView({nodeId, playground, linkStepId}: NodeViewProps) {


    // TODO: Test the deleted node

    const node = playground.getNodeInfo(nodeId);
    const nodeSection = node ? node.component : <div className="deleted-node">
        <span>?</span>
    </div>;

    const nv = <div className="node-view">
        {<div className="node-name">{node?.name}</div>}
        {nodeSection}
    </div>;

    if (linkStepId)
        return <a href={"#step-" + linkStepId}>{nv}</a>;
    return nv;
}

type RunKibitzerEventViewProps = {
    playground: PlaygroundInfo
    event: RuntimeEvent
    previousEvent: RuntimeEvent | null
    nextEvent: RuntimeEvent | null
    first: boolean;
    last: boolean;
    globalStartTime: string;
}

function RunKibitzerEventView(props: RunKibitzerEventViewProps) {
    const {playground, event, globalStartTime, previousEvent, nextEvent, last} = props;

    const dataIn = event.attempt.data_in;
    const dataOut = event.attempt.data_out;

    const eventStartTime = event.step.run_at;
    const previousEventStartTime = previousEvent?.step.run_at ?? globalStartTime;
    const diff = timeDifference(previousEventStartTime, eventStartTime);

    const isFailure = event.step.outcome === "failure";

    const causedByPreviousStepInTimeline = event.causedBy === "start" || (previousEvent && previousEvent.step.id === event.causedBy.step.id);
    const showCausedBox = (event.caused.length > 1) || (event.caused.length === 1 && event.caused[0].step.id !== nextEvent?.step.id);
    const causesNextStepInTimeline = last || !!(event.caused.find(it => it.step.id === nextEvent?.step.id));


    return <div className={classNames("event", {"failure": isFailure})} id={`step-${event.step.id}`}>
        <div className="time-flow">

            {causedByPreviousStepInTimeline &&
				<div className="timespan big"></div>
            }

            {!causedByPreviousStepInTimeline && event.causedBy !== "start" && <>
				<div className="timespan big from-nothing"></div>
				<div className="caused-group">
					<div className="caused-group-title">Caused By</div>
					<div className={"caused"}>
						<NodeView nodeId={event.causedBy.node.node_id}
								  linkStepId={event.causedBy.step.id}
								  playground={playground}/>
					</div>
				</div>
				<div className="timespan big"></div>

			</>}

            {diff && <>
				<EventTimeDifferenceView time={eventStartTime} diff={diff}/>
				<div className="timespan small"></div>
			</>}

            {isData(dataIn) && <>
				<DataNodeView data={dataIn} title={"Data Context"}/>
				<div className="timespan small"></div>
			</>}

            <div className="attempt">
                <div className="canvas-phase-data sidebar">
                    <div className="data-view">
                        <div className="title">Flow</div>
                        <div className="content">{event.phase.debug["@phase_name"]}</div>
                    </div>

                    <NodeView nodeId={event.node.node_id} playground={playground}/>
                </div>

                <div className="logs-container">
                    <JobKibitzerLogsView playground={playground} runId={event.run.id} stepId={event.step.id}/>
                </div>
                <div className="sidebar">
                    <NodeOutcomeView outcome={event.step.outcome}/>
                </div>
            </div>

            {isData(dataOut) && <>
				<div className="timespan small"></div>
				<DataNodeView data={dataOut} title={"Result Data Context"}/>
			</>}

            {showCausedBox && <>
				<div className="timespan"></div>
				<div className="caused-group">
					<div className="caused-group-title">Caused Steps</div>
					<div className={"caused"}>
                        {
                            event.caused.map((it) => {
                                const nodeId = it.node.node_id;
                                const stepId = it.step.id;
                                return <NodeView
                                    key={it.step.id}
                                    nodeId={nodeId}
                                    linkStepId={stepId}
                                    playground={playground}/>;
                            })
                        }
					</div>
				</div>
			</>}

            {causesNextStepInTimeline ?
                <div className="timespan big"></div> :
                <div className="timespan big from-nothing"></div>
            }
        </div>
    </div>;
}


type RunKibitzerProps = {
    playground: PlaygroundInfo
    runtime: RuntimeSeries,
}

function getLastEventTime(runtime: RuntimeSeries, condition: RunCondition): string {
    let last = runtime.run.created_on;
    for (const event of condition.events)
        last = event.step.run_at;
    return last;
}

type RunKibitzerPropsWithCondition = RunKibitzerProps & { condition: RunCondition }

export function PlaygroundRunKibitzerView(props: RunKibitzerPropsWithCondition) {
    const {playground, runtime, condition} = props;

    if (runtime.overallStatus !== "RUNNING") {
        if (condition.events.length === 0) {
            return <div className={"no-events"}>There were no events...</div>;
        }
    }

    const conditionName = condition.canvas.debug["@canvas_name"];

    const startNode = <div className="event">
        <div className="time-flow">
            <Tooltip title={`Started on: ${runtime.run.created_on}`}>
                <div className="time-flow-terminator start">
                    <div className="time-flow-terminator-text">Matched <strong>{conditionName}</strong></div>
                    <div className="time-flow-terminator-time">
                        {fromNow(runtime.run.created_on)}
                    </div>
                </div>
            </Tooltip>
            <div className="timespan"></div>
        </div>
    </div>;

    const lastNodeTs = getLastEventTime(runtime, condition);
    const isRunning = runtime.overallStatus === "RUNNING";
    const events = condition.events;

    const lastNode = <div className="event">
        <div className={"time-flow"}>
            <div className="timespan"></div>
            <Tooltip title={`${lastNodeTs}`}>
                <div
                    className={classNames("time-flow-terminator", "end", {"running": isRunning})}>
                    <div className="text">
                        {isRunning ? <CircularProgress/> : "Completed"}
                    </div>
                    {isRunning ? <></> : <div className="time">{fromNow(lastNodeTs)} </div>}
                </div>
            </Tooltip>
        </div>
    </div>;

    return <>
        {startNode}
        {events.map((runEvent, i) => {
            const first = i === 0;

            const last = events.length === i + 1;

            const previousEvent: RuntimeEvent | null = i > 0 ? events[i - 1] : null;
            const nextEvent: RuntimeEvent | null = i < events.length - 1 ? events[i + 1] : null;

            return <RunKibitzerEventView
                key={i}
                playground={playground}
                first={first}
                last={last}
                previousEvent={previousEvent}
                nextEvent={nextEvent}
                globalStartTime={runtime.run.created_on}
                event={runEvent}/>;
        })}
        {lastNode}
    </>;
}


function RuntimeViewTabs(props: RunKibitzerProps) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const tabs: React.ReactNode[] = [];
    const panels: React.ReactNode[] = [];

    if (props.runtime.overallStatus === "NOOP") {
        return <UpwireModalWarning message={"When executing this playground, no condition matched."}/>;
    }

    if (props.runtime.run.debug.error) {
        return <UpwireModalError message={props.runtime.run.debug.error.message}/>;
    }

    for (let i = 0; i < props.runtime.conditions.length; i++) {

        const condition = props.runtime.conditions[i];

        const statusName = condition.overallStatus.toLowerCase();

        const label = <Chit text={condition.canvas.debug["@canvas_name"]}
                            size={"normal"}
                            className={classNames("status-name", "pointer", statusName)}/>;

        tabs.push(<Tab label={label} key={condition.canvas.id}/>);

        panels.push(<TabsPanel value={value} index={i} key={condition.canvas.id}>
            <PlaygroundRunKibitzerView condition={condition} {...props}/>
        </TabsPanel>);
    }

    return (
        <div>
            <div className="runtime-tabs">
                <Tabs
                    value={value}
                    onChange={handleChange}
                >
                    {tabs}
                </Tabs>
            </div>
            {panels}
        </div>
    );
}

function PlaygroundRunKibitzerViewTabulated(props: RunKibitzerProps) {
    const {runtime} = props;
    const runtimeDescription = <div className="runtime-description">
        <div className="descriptor">
            <div className="title">Playground</div>
            <div className="content">{runtime.run.debug["@playground_name"]}</div>
        </div>
        <div className="descriptor">
            <div className="title">Status</div>
            <div className="content">{runtime.overallStatus}</div>
        </div>
    </div>;

    return <>
        {runtimeDescription}
        <RuntimeViewTabs {...props} />
    </>;
}

export function PlaygroundRunKibitzer(props: RunKibitzerProps) {
    return <div className="kibitzer-host">
        <PlaygroundRunKibitzerViewTabulated {...props}/>
    </div>;
}
