import {DispatchApi} from "../../../core/dispatch/dispatch";
import {useToken} from "../../../state/UserContextProvider";
import {TestCase, VariableValues} from "../../components/common/testCase";
import {raiseGlobalError} from "../../../core/dispatch/errors";
import {Inputs, Playground, Version} from "../../components/playground/types";
import {VarContext} from "../../../components/VarContext/data";

export type PlaygroundRunStatus =
    "RUNNING" | "PENDING" | "COMPLETED" | "FAILED" | "ENDED" | "NOOP"
    | "QUEUED" | "DEFERRED" | "RETRY" | "TIMED_OUT" | "CANCELLED";

export type Data = { [key: string]: any };
export type Status = PlaygroundRunStatus
export type Outcome = "success" | "failure"

export enum LogLevel {
    INFO = 4,
    Warning = 3,
    Error = 2,
    Fatal = 1
}

export function getLogLevelName(level: LogLevel) {
    switch (level) {
        case LogLevel.INFO:
            return "info";
        case LogLevel.Warning:
            return "warning";
        case LogLevel.Error:
            return "error";
        case LogLevel.Fatal:
            return "fatal";
    }
}

export type PlaygroundRun = {
    "id": string,
    "namespace": string,
    "playground_id": string,
    "version_id": string,
    "correlation_id": string,
    "status": Status,
    "data_source_type": "DATA",
    "init_params": Data,
    "active_params": Data,
    "session": Data,
    "debug": {
        "@playground_name": string,
        "@taken_ms": number,
        "error"?: {
            "message": string,
        }
    }

    "created_on": string,
    "created_by": string,
    "canvas_runs": CanvasRun[],
}

export type CanvasRun = {
    "id": string,
    "canvas_id": string,
    "version_id": string,
    "status": Status,
    "debug": {
        "@canvas_name": string,
        "@taken_ms": number,
    }
    "phase_runs": PhaseRun[]
}

export type PhaseRun = {
    "id": string,
    "phase_id": string,
    "status": Status,
    "init_env": Data,
    "init_params": Data,
    "active_params": Data,
    "session": Data,
    "debug": {
        "@phase_name": string,
        "@taken_ms": number,
    }
    "nodes": NodeRun[]
}

export type NodeRun = {
    "id": string,
    "node_id": string,
    "state": {
        "@node_execution_count": number,
    }
    "debug": {
        "@node_name": string,
        "@node_type": string,
    },
    "steps": Step[]
}

export type Step = {
    "id": string,
    "from_step_id": string,
    "status": Status,
    "state": {
        "@node_execution_seq": number,
    }
    outcome: Outcome,
    init_env: Data,
    in_params: Data,
    out_params: Data,
    session: Data,
    created_on: string,
    run_at: string,
    "debug": {
        "@node_module": string,
        "@taken_ms": number,
        "attempts": StepAttempt[]
    }
}

export type StepAttempt = {
    "debug": {
        "@attempted_on": string,
        "@module_run_id": string,
        "@module_taken_ms": number,
        "@taken_ms": number,
        "logs": RunLog[]
        "error"?: {
            "message": string,
        }
    }
    "env": Data,
    "data_in": Data,
    "data_out": Data,
    "in_status": Status,
    "settings": {
        "@node_name": string,
    }
    "state": {
        "@node_execution_seq": number,
        "@node_execution_count": number,
        "@step_attempt_count": number,
    }
}

export type RunLog = {
    "id": number, // BROKEN!!!
    "level": LogLevel,
    "created_on": string,
    "log": string,
    "step_id": string,
}

export type RuntimeEvent = {

    attempt: StepAttempt
    step: Step
    node: NodeRun
    phase: PhaseRun
    canvas: CanvasRun
    run: PlaygroundRun,
    status: Status

    causedBy: RuntimeEvent | "start"
    caused: RuntimeEvent[]
}

export type RunCondition = {
    canvas: CanvasRun,
    events: RuntimeEvent[]
    overallStatus: Status
}

export type RuntimeSeries = {
    run: PlaygroundRun,
    conditions: RunCondition[]
    overallStatus: Status
}


type ApiPayloadData = {
    testCase: TestCase | null;
    variables: Inputs[];
    playgroundId: string;
    version: Version;

    varContexts: VarContext[];
}

export function constructApiPayload(data: ApiPayloadData): [string, any] {
    const endpoint = `/api/v1/playground/exec/start`;

    const params: any = {};
    for (let variable of data.variables) {
        let value: string = variable.default ?? "";
        if (data.testCase)
            if (variable.name in data.testCase.values)
                value = data.testCase.values[variable.name];

        params[variable.name] = value;
    }

    let items: any = {};
    let contexts: any = undefined;
    if (data.testCase) {
        const name = data.testCase.name;
        items[name] = params;

        if (data.varContexts) {
            const map: any = {};
            for (let contextName in data.testCase.varContexts) {
                const contextId = data.testCase.varContexts[contextName];
                const found = data.varContexts.find(c => c.id === contextId);
                if (found)
                    map[contextName] = found.name;
            }

            if (Object.keys(map).length > 0)
                contexts = map;
        }
    }

    const payload: any = {
        "playgroundId": data.playgroundId,
        "params": params,
        "version": data.version,
        "tag": "api-test"
    };

    if (contexts) {
        payload["contexts"] = contexts;
    }

    return [endpoint, payload];
}


function withDefaultValues(testCase: TestCase, playground: Playground): VariableValues {
    const result = {...testCase.values};

    for (let variable of playground.settings.variables) {
        if (variable.default && variable.required && !result[variable.name]) {
            result[variable.name] = variable.default;
        }
    }

    return result;
}

export class PlaygroundDispatchApi extends DispatchApi {

    constructor(token: string | undefined | null) {
        super(token, "playground", false);
    }

    async getRuns(playgroundId: string, limit: number = 10) {
        return await this.dispatch<PlaygroundRun[]>("listRuns", {
            "playgroundId": playgroundId,
            "limit": limit
        });
    }

    async getRuntimeSeries(runId: string): Promise<PlaygroundRun> {
        return await this.dispatch<PlaygroundRun>("getRunInfo", {"runId": runId});
    }

    async getRunLogs(runId: string) {
        return await this.dispatch<RunLog[]>("getRunLogs", {"runId": runId});
    }

    async promote(playgroundId: number, target: string) {
        return await this.dispatch<void>("promotePlayground", {"playgroundId": playgroundId, "target": target});
    }

    async runTestCase(playground: Playground, testCase: TestCase, varContexts: VarContext[], version: Version = "head"): Promise<any> {

        testCase = JSON.parse(JSON.stringify(testCase));
        testCase.values = withDefaultValues(testCase, playground);

        const [endpoint, payload] = constructApiPayload({
            testCase: testCase,
            variables: playground.settings.variables,
            playgroundId: playground.playground_id,
            version: version,
            varContexts: varContexts,
        });

        const response = await fetch(endpoint, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        });


        if (!response.ok) {
            raiseGlobalError("Unable to launch test case");
        }

        return await response.json();
    }
}


export function usePlaygroundDispatchApi() {
    return new PlaygroundDispatchApi(useToken());
}
